import { styled } from "styled-components";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { YellowButton } from "@/outscal-commons-frontend/Styled/Buttons";
import Image from "next/image";
import { AiFillCloseCircle } from "react-icons/ai";

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  gap: 18px;
  text-align: center;
  position: relative;
  padding: ${(p) => p.padding || "28px 40px"};
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const Title = styled.label`
  font-size: ${(p) => p.theme.fontSize.xxxxl};
  font-weight: ${(p) => p.theme.fontWeight.midBold};
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.xxxl};
  }
`;

export const HTMLMessage = styled(Div)``;

export const Message = styled(Text)`
  color: ${(p) => p.theme.colors[p.messageColor]};
  font-size: ${(p) => p.theme.fontSize[p.messageFontSize]};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.md};
  }
  margin: auto;
`;

export const CTA = styled(YellowButton)`
  padding: 10px 30px;
  margin: auto;
  border-radius: ${(p) => p.theme.borderRadius.xxs};
`;

export const CloseIcon = styled(AiFillCloseCircle)`
  position: absolute;
  z-index: 99;
  font-size: 22px;
  color: ${(p) => p.theme.colors.textGrey};
  top: -16px;
  right: -26px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    right: -8px;
  }
`;

export const MessageImage = styled(Image)`
  margin: auto;
  @media screen and (max-width: 767px) {
    height: 50px;
    width: 50px;
  }
`;
