import { AnalyticsManager } from "@/outscal-commons-frontend";
import { useEffect, useRef } from "react";

const useElementVisibilityAnalytics = (threshold = 0.5) => {
  const observerRef = useRef(null);
  const trackedElements = useRef(new Set());

  const trackVisibilityEvent = (viewAnalyticsData) => {
    const [UI, elementId] = viewAnalyticsData.split(",");
    AnalyticsManager.trackViewEvent(UI, elementId);
  };

  useEffect(() => {
    const onIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const viewAnalyticsData = entry.target.getAttribute("view-analytics");
          if (viewAnalyticsData) {
            trackVisibilityEvent(viewAnalyticsData);
          }
        }
      });
    };

    const observerOptions = {
      threshold: Number.isFinite(threshold) ? threshold : 0.5,
    };
    observerRef.current = new IntersectionObserver(onIntersect, observerOptions);

    const observeElements = () => {
      const elements = document.querySelectorAll("[view-analytics]");
      elements.forEach((element) => {
        if (!trackedElements.current.has(element)) {
          trackedElements.current.add(element);
          observerRef.current.observe(element);
        }
      });
    };

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          observeElements();
        }
      });
    });

    mutationObserver.observe(document.body, { childList: true, subtree: true });
    observeElements();

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
      mutationObserver.disconnect();
    };
  }, [threshold]);

  return null;
};

export default useElementVisibilityAnalytics;
