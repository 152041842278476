import React, { useEffect, useState } from "react";
import { PopUp, PopUpBG, BlurBG } from "./PopUp";
import { AnalyticsManager } from "../Managers";
import { Actions, CommonElements, UIElements } from "../Hooks/useAnalyticsClickEvent";

const PopupTemplate = ({
  setShowPopUp = () => {},
  paddingVertical,
  id = "untracked-popup",
  paddingHorizontal,
  width,
  height,
  children,
  widthvw,
  paddingvw,
  padding,
  zIndex,
  minWidth,
  maxWidth,
  maxHeight,
  animationDuration = 0.1,
  bgColor = "white",
  borderRadius = "md",
  overflow,
  blurBg = true,
}) => {
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    AnalyticsManager.actionEvent(
      UIElements.CARD,
      id,
      CommonElements.POPUP,
      undefined,
      Actions.open
    );
    return () => {
      AnalyticsManager.actionEvent(
        UIElements.CARD,
        id,
        CommonElements.POPUP,
        undefined,
        Actions.close
      );
    };
  }, []);

  return (
    <div id={id}>
      {blurBg && (
        <BlurBG
          fadeIn={fadeIn}
          style={zIndex && { zIndex: zIndex }}
          animationDuration={animationDuration}
          onMouseDown={(e) => {
            setFadeIn(false);
            setTimeout(() => {
              setShowPopUp(false);
            }, 70);
          }}
        />
      )}
      <PopUpBG
        style={zIndex && { zIndex: zIndex }}
        animationDuration={animationDuration}
        fadeIn={fadeIn}
        onMouseDown={(e) => {
          setFadeIn(false);
          setTimeout(() => {
            setShowPopUp(false);
          }, 70);
        }}
      >
        <PopUp
          overflow={overflow}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          bgColor={bgColor}
          padding={padding}
          paddingvw={paddingvw}
          widthvw={widthvw}
          paddingVertical={paddingVertical}
          paddingHorizontal={paddingHorizontal}
          width={width}
          height={height}
          minWidth={minWidth}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          borderRadius={borderRadius}
        >
          {children}
        </PopUp>
      </PopUpBG>
    </div>
  );
};

export default PopupTemplate;
