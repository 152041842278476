import { PopupTemplate, RowDiv } from "@/outscal-commons-frontend/Styled";
import React from "react";
import {
  CTA,
  CrossIcon,
  HTMLMessage,
  Header,
  Message,
  Root,
  Title,
  MessageImage,
  CloseIcon,
} from "./MessagePopup.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const MessagePopupView = ({
  title,
  hidePopups,
  message,
  action,
  ctaText,
  image,
  imageSize,
  htmlMessage,
  messageColor,
  messageFontSize,
  width,
  padding,
  maxWidth,
  dismissable = true,
  analyticsText,
  metaText,
}) => {
  return (
    <PopupTemplate
      setShowPopUp={() => {
        dismissable ? hidePopups() : () => {};
      }}
      maxWidth={maxWidth}
      width={width}
      height="fit-content"
      bgColor="white"
      overflow="hidden"
    >
      <Root id="message_popup">
        {dismissable && (
          <RowDiv style={{ position: "relative" }}>
            <CloseIcon
              onClick={hidePopups}
              data-analytics={[
                UIElements.BUTTON,
                `message_close_${analyticsText ? analyticsText : ""}`,
              ]}
            />
          </RowDiv>
        )}
        {image && (
          <MessageImage
            height={imageSize}
            width={imageSize}
            style={{ objectFit: "contain" }}
            src={image}
          />
        )}
        {title && (
          <Header>
            <Title>{title}</Title>
          </Header>
        )}
        {message && (
          <Message
            messageColor={messageColor}
            messageFontSize={messageFontSize}
          >
            {message}
          </Message>
        )}
        {htmlMessage && (
          <HTMLMessage
            dangerouslySetInnerHTML={{ __html: htmlMessage }}
          ></HTMLMessage>
        )}
        {ctaText && (
          <CTA
            onClick={action}
            data-analytics={[
              UIElements.BUTTON,
              analyticsText ? `${analyticsText}-cta` : "message-popup",
            ]}
          >
            {ctaText}
          </CTA>
        )}
        {metaText && (
          <Message messageColor={"black"} messageFontSize={"sm"}>
            {metaText}
          </Message>
        )}
      </Root>
    </PopupTemplate>
  );
};

export default MessagePopupView;
