import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { setPopup, clearPopups } from "@/store/reducers/popupReducer";
export const LOGIN_POPUP = 0;
export const REGISTER_POPUP = 1;
export const PRO_REGISTER_POPUP = 34;
export const FORGOT_PASSWORD_POPUP = 2;
export const GUEST_LOGIN_POPUP = 3;
export const WAITLIST_POPUP = 4;
export const UPGRADE_PLAN_POPUP = 5;
export const ASSIGNMENT_SUBMIT_POPUP = 6;
export const ASSIGNMENT_SUBMISSIONS_POPUP = 7;
export const JOIN_WAITLIST_TYPEFORM = 8;
export const JOB_ALERT_POPUP = 9;
export const MCQ_POPUP = 10;
export const PAYMENT_OPTION_POPUP = 11;
export const IFRAME_POPUP = 12;
export const REPORT_JOB_POPUP = 13;
export const PHONE_NUMBER_POPUP = 14;
export const IGDC_POPUP = 15;
export const EDIT_GAME_BUILD_INFO_POPUP = 16;
export const FEE_STRUCTURE_POPUP = "fee-emi-plans";
export const RESUME_POPUP = 17;
export const REVIEW_FEEDBACK_POPUP = 18;
export const REVIEW_FEEDBACK_ALERT_POPUP = 19;
export const WAITLIST_APPLICATION_COMPLETED = 20;
export const APPLY_TO_SIMILAR_JOBS_POPUP = 21;
export const RESUBMIT_FAILED_ASSIGNMENT_POPUP = 22;
export const MESSAGE_POPUP = 23;
export const ASSESSMENT_POPUP = 24;
export const ADD_SKILLS_POPUP = 25;
export const ADS_JOINWAITLIST_POPUP = 26;
export const JOB_APPLY_CONFIRMATION_POPUP = 28;
export const EMAIL_VERIFICATION_TOKEN_INPUT_POPUP = 29;
export const EMAIL_VERIFICATION_EMAIL_INPUT_POPUP = 30;
export const VALUE_CAPTURE_REGISTER_POPUP = 32;
export const INPUT_POPUP = 33;
export const PRO_UNLOCKED_INFO_POPUP = 35;
export const INPUT_FORM_POPUP = 36;
export const RESULT_SCREEN_POPUP = 37;

export const POPUP_COMPONENT_MAP = {
  [PRO_UNLOCKED_INFO_POPUP]: "congrats",
};

const usePopupActions = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { popup, data } = useSelector((state) => state.popup);

  const setPopupQueryParam = (value) => {
    let query = { ...router.query, ["popup"]: value };
    router.push(
      {
        pathname: router.pathname,
        query: query,
      },
      undefined,
      { shallow: true }
    );
  };

  const deletePopupQueryParam = () => {
    let updatedQuery = { ...router.query };
    delete updatedQuery["popup"];
    router.push(
      {
        pathname: router.pathname,
        query: updatedQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  const showPopup = (popup, data) => {
    dispatch(setPopup({ popup, data }));
    setPopupQueryParam(popup);
  };

  const hidePopups = () => {
    dispatch(clearPopups());
    deletePopupQueryParam();
  };

  return { showPopup, hidePopups, popup, data };
};

export default usePopupActions;
