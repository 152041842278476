import LazySingleton from "@/utils/DesignPatternHelpers/LazySingleton";
import {
  IntentMapType,
  IntentObjectType,
  UTMParamsType,
} from "./Service.types";

const IntentMap: IntentMapType = {
  course: "COURSE",
  job: "JOB",
  company: "COMPANY",
};

class UTMManager extends LazySingleton {
  public utmParams: UTMParamsType = {};
  public sessionUtmParams: UTMParamsType = {};

  constructor() {
    super();
    this.initializeUtms();
  }

  private initializeUtms(): void {
    const queryParams = new URLSearchParams(window.location.search);
    const referrer = document.referrer;
    if (
      referrer &&
      !referrer.includes(window.location.hostname) &&
      !referrer.includes("accounts.google.com")
    ) {
      this.utmParams["utm_referrer_url"] = referrer;
    } else {
      this.utmParams["utm_referrer_url"] = "direct";
    }

    const utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];

    utmKeys.forEach((key) => {
      const value = queryParams.get(key);
      if (value) {
        this.utmParams[key] = value;
      }
    });

    sessionStorage.setItem("utms", JSON.stringify(this.utmParams));
    this.sessionUtmParams = this.getSessionUtms();
    if (!localStorage.getItem("firstLandingUtms")) {
      localStorage.setItem("firstLandingUtms", JSON.stringify(this.utmParams));
    } else {
      this.utmParams = this.getFirstLandingUtms();
    }
    this.setIntent();
  }

  // Retrieve the stored UTMs from sessionStorage
  private getSessionUtms(): UTMParamsType {
    const storedUtms = sessionStorage.getItem("utms");
    return storedUtms ? JSON.parse(storedUtms) : {};
  }

  // Retrieve the first landing UTMs from localStorage
  private getFirstLandingUtms(): UTMParamsType {
    const storedFirstLandingUtms = localStorage.getItem("firstLandingUtms");
    return storedFirstLandingUtms ? JSON.parse(storedFirstLandingUtms) : {};
  }
  // Set Intent using window.location.pathname and window.location.href (asPath)
  private setIntent(): void {
    const intentObj = this.getIntent();
    if (!localStorage.getItem("landingIntent")) {
      localStorage.setItem("landingIntent", JSON.stringify(intentObj));
    }
    if (!sessionStorage.getItem("retargetingIntent")) {
      sessionStorage.setItem("retargetingIntent", JSON.stringify(intentObj));
    }
  }

  public getIntent(): IntentObjectType {
    let intent = "other";
    const pathname = window.location.pathname;
    const asPath = window.location.href;
    Object.keys(IntentMap).map((key) => {
      if (pathname.includes(key)) {
        intent = IntentMap[key];
      }
    });

    let updatedURL = new URL(asPath);

    // Loop through all utmParams in sessionUtmParams and add them to the URL if not already present
    Object.keys(this.sessionUtmParams).forEach((key) => {
      if (!updatedURL.searchParams.has(key) && this.sessionUtmParams[key]) {
        updatedURL.searchParams.append(key, this.sessionUtmParams[key]);
      }
    });

    const intentObj: IntentObjectType = { intent, url: updatedURL.toString() };
    return intentObj;
  }

  public getUtms(): UTMParamsType {
    return this.getFirstLandingUtms();
  }

  public getLandingIntent(): IntentObjectType {
    const landingIntent = localStorage.getItem("landingIntent");
    return landingIntent ? JSON.parse(landingIntent) : {};
  }

  public getRetargetingIntent(): IntentObjectType {
    const retargetingIntent = sessionStorage.getItem("retargetingIntent");
    return retargetingIntent ? JSON.parse(retargetingIntent) : {};
  }
}

export default UTMManager;
