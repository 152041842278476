/**
 * @description it takes share link of youtube or loom and returns embed link
 * @param {string} link
 * @returns embed link
 */
export const embedUrl = (link) => {
  let url = link;
  if (link.includes("youtu")) {
    if (link.includes("watch")) {
      url = new URL(link);
      let params = new URLSearchParams(url.search);
      let v = params.get("v");
      url = "https://www.youtube.com/embed/" + v;
    } else {
      let id = link.split("/").pop();
      url = "https://www.youtube.com/embed/" + id;
    }
  }

  if (link.includes("loom")) {
    let id = link.split("/").pop();
    url = "https://www.loom.com/embed/" + id;
  }

  return url;
};

export function getFullUrl(
  params,
  frontbase = "",
  lastBase = "",
  returnPathOnly = false
) {
  const protocol = "https"; // Or http, depending on your setup
  const host = "outscal.com"; // Your domain

  let pathSegments = params
    ? Object.values(params).map((segment) => encodeURIComponent(segment))
    : [];

  let path = "";
  pathSegments = pathSegments.join("/");
  if (frontbase !== "" && lastBase !== "") {
    path = `/${frontbase}/${pathSegments}/${lastBase}`;
  } else if (frontbase !== "" && pathSegments !== "") {
    path = `/${frontbase}/${pathSegments}`;
  } else if (lastBase !== "") {
    path = `/${pathSegments}/${lastBase}`;
  } else {
    path = `/${frontbase}`;
  }

  return returnPathOnly ? `${path}` : `${protocol}://${host}${path}`;
}

export function getUpdatedJobSlug(jobSlug) {
  const jobSlugWords = jobSlug.split("_");
  let updatedJobSlug = "";

  if (jobSlugWords.length === 3) {
    updatedJobSlug =
      jobSlugWords.slice(0, 2).join("-at-") + "-" + jobSlugWords[2];
  } else {
    updatedJobSlug = jobSlugWords.slice(0, 2).join("-at-");
    updatedJobSlug += "-in-" + jobSlugWords[2];
    updatedJobSlug += "-" + jobSlugWords[jobSlugWords.length - 1];
  }

  return updatedJobSlug;
}

export const convertObjectToQuery = (obj) => {
  let queryString = "";
  queryString = Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return queryString;
};

export const getParams = (url) => {
  const baseUrl = "https://outscal.com/";
  const params = {};
  const parser = new URL(url, baseUrl);
  const queryString = parser.search.substring(1);
  const queryParts = queryString.split("&");

  queryParts.forEach((part) => {
    const [key, value] = part.split("=");
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return params;
};

// export const getQueryParam = (url, param) => {
//   let queryString = url.split("?")[1];
//   let params = queryString.split("&");
//   for (let i = 0; i < params.length; i++) {
//     let keyValue = params[i].split("=");
//     if (keyValue[0] === param) {
//       return keyValue[1];
//     }
//   }
//   return null;
// };

export const getQueryParam = (url, param) => {
  let regex = new RegExp(`[?&]${param}=([^&]*)`);
  let match = url.match(regex);
  return match ? match[1] : null;
};

export const getJobFilterQueryObjFromString = (
  url = "",
  keys = [
    "skills",
    "categoryTags",
    "companies",
    "datePosted",
    "jobType",
    "experienceLevel",
    "location",
    "locationType",
    "jobCategoryType"
  ],
  isFilters = false
) => {
  const params = {};
  let parser = null;
  if (url.startsWith("/")) {
    parser = new URL(url, "https://outscal.com");
  } else {
    parser = new URL(url);
  }

  const queryString = parser ? parser.search.substring(1) : url;
  const queryParts = queryString.split("&");

  queryParts.forEach((part) => {
    const [key, value] = part.split("=");
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    if (keys.length === 0 || keys.includes(decodedKey)) {
      params[decodedKey] = params[decodedKey] = decodedValue
        .split(",")
        .map((item) => ({ id: item }));
    }
  });

  return params;
};

export const removeQueryParam = (url, paramName) => {
  const regex = new RegExp(`([&?])${paramName}=[^&]*`, "i");  
  let modifiedUrl = url.replace(regex, (match, delimiter) => {
    return delimiter === '?' && url.includes('&') ? '?' : '';
  });

  return modifiedUrl.replace(/(\?&)|(&\?)/, '?').replace(/\?$/, '');
};
