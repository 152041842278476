import { useEffect, useRef } from "react";
import AnalyticsManager from "../Managers/Analytics/AnalyticsManager";
import { useRouter } from "next/router";
import { Actions } from "./useAnalyticsClickEvent";

const useAnalyticsClickEvent = () => {
  const listenersAdded = useRef(false);
  const shouldUpdate = useRef(true);
  const elementsWithAnalyticsData = useRef();
  const router = useRouter();
  const prevURL = useRef(null);
  const getAnalyticsData = (target) => {
    let analyticsData = target.getAttribute("data-analytics");
    if (!analyticsData) {
      const parentElementWithDataAttribute = target.closest("[data-analytics]");
      if (parentElementWithDataAttribute) {
        analyticsData =
          parentElementWithDataAttribute.getAttribute("data-analytics");
      }
    }
    return analyticsData;
  };
  const onClickHandler = (event) => {
    const analyticsData = getAnalyticsData(event.target);
    const [UI, label, commonElement, CET_Name, action] =
      analyticsData.split(",");
    const page = AnalyticsManager.getPageKey();
    AnalyticsManager.trackEvent(
      page,
      UI,
      label,
      commonElement,
      CET_Name,
      action
    );
  };
  const addListeners = () => {
    listenersAdded.current = true;
    elementsWithAnalyticsData.current =
      document.querySelectorAll("[data-analytics]");
    // console.log(elementsWithAnalyticsData.current.length);
    elementsWithAnalyticsData.current.forEach((element) => {
      element.addEventListener("click", onClickHandler);
    });
  };

  const removeListeners = () => {
    if (listenersAdded.current) {
      listenersAdded.current = false;
      elementsWithAnalyticsData.current.forEach((element) => {
        element.removeEventListener("click", onClickHandler);
      });
    }
  };

  const handleRouteChange = (url) => {
    const cleanUrl = url.split("?")[0].split("#")[0];
    if (prevURL.current !== cleanUrl) {
      AnalyticsManager.actionEvent(
        undefined,
        cleanUrl,
        undefined,
        undefined,
        Actions.pageVisit
      );
      prevURL.current = cleanUrl;
    }
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    handleRouteChange(router.asPath);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          if (shouldUpdate.current) {
            shouldUpdate.current = false;
            setTimeout(() => {
              removeListeners();
              addListeners();
              shouldUpdate.current = true;
            }, 1000);
          }
        }
      }
    });
    addListeners();
    observer.observe(document, { childList: true, subtree: true });
    return () => {};
  }, []);

  return null;
};
export default useAnalyticsClickEvent;
