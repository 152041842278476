import PopupTemplate from "@/outscal-commons-frontend/Styled/PopupTemplate";
import React from "react";

import { Root, Heading, SubHeading } from "./InputPopup.styles";
import InputTextFieldWithButton from "@/outscal-commons-frontend/FormBuilder/DefaultInputUI/InputTextFieldWithButton";

const InputPopupView = ({
  hidePopups,
  heading,
  subHeading,
  formData,
  onSubmit,
  submitText,
}) => {
  return (
    <PopupTemplate
      width="90%"
      maxWidth="650px"
      setShowPopUp={hidePopups}
      height="fit-content"
    >
      <Root>
        <Heading>{heading}</Heading>
        <SubHeading>{subHeading}</SubHeading>
        <InputTextFieldWithButton
          {...formData?.email}
          onButtonClick={onSubmit}
          buttonText={submitText}
        />
      </Root>
    </PopupTemplate>
  );
};

export default InputPopupView;
