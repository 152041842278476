import dynamic from "next/dynamic";
const LoginPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/LoginPopup/LoginPopup.controller"
  )
);
const InputFormPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/InputFormPopup/InputFormPopup.controller"
  )
);
const RegisterPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/RegisterPopup/RegisterPopup.controller"
  )
);
const ValueCaptureRegisterPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/ValueCaptureRegisterPopup/ValueCaptureRegisterPopup.controller"
  )
);
const ForgotPasswordPopup = dynamic(() =>
  import("@/Components/Popup/AppPopups/ForgotPasswordPopup")
);
const GuestLoginPopup = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AuthPopups/GuestLoginPopup/GuestLoginPopup.controller"
  )
);
const WaitlistConfirmationPopup = dynamic(() =>
  import("@/Components/Popup/WaitlistConfirmationPopup")
);
const ShowAssignmentSubmitPopUp = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/AssignmentPopup/ShowAssignmentSubmitPopUp"
  )
);
const ShowAssignmentSubmissionsPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/AssignmentPopup/ShowAssignmentSubmissionsPopup"
  )
);
const JoinWaitlistTypeformPopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/Typeform/JoinWaitlistTypeformPopup")
);
const JobAlertPopup = dynamic(() => import("@/Components/Popup/JobAlertPopup"));
const McqPopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/JoinedWaitlistPopups/MCQPopup")
);
const PaymentOptionPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/JoinedWaitlistPopups/Payments/PaymentOptionPopup"
  )
);
const IframePopup = dynamic(() => import("@/Components/Popup/IframePopup"));
const ReportJobPopup = dynamic(() =>
  import("@/Components/Popup/JobPopups/ReportJobPopup")
);
const IGDCPopup = dynamic(() =>
  import("@/Components/Popup/GenericPopups/IGDCPopup")
);
const FeeStructurePopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/FeeStructure/FeeStructurePopup")
);
const EditGameBuildInfoPopup = dynamic(() =>
  import("@/Components/Popup/EditInfoPopups/EditGameBuildInfoPopup")
);
const ResumePopup = dynamic(() =>
  import("@/Components/Popup/GenericPopups/ResumePopup")
);
const ReviewFeedbackPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/ReviewFeedbackPopup/ReviewFeedbackPopup"
  )
);
const ReviewFeedbackAlertPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/ReviewFeedbackPopup/ReviewFeedbackAlertPopup"
  )
);
const WaitlistApplicationCompletedPopup = dynamic(() =>
  import(
    "@/Components/Popup/CoursePopups/WaitlistApplicationPopup/WaitlistApplicationCompletedPopup"
  )
);
const ApplyToSimilarJobsPopup = dynamic(() =>
  import(
    "@/Components/Popup/JobPopups/ApplyToSimilarJobsPopup/ApplyToSimilarJobsPopup"
  )
);
const ResubmitFailedAssignmentPopup = dynamic(() =>
  import("@/Components/Popup/CoursePopups/ResubmitFailedAssignmentPopup")
);
const AssessmentPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/AssessmentPopup/AssessmentPopup.controller"
  )
);

const JobApplyConfirmationPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/JobApplyConfirmationPopup/JobApplyConfirmationPopup.controller"
  )
);

const EmailInputPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/EmailVerificationPopup/EmailInputPopup/EmailInputPopup.controller"
  )
);
const TokenInputPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/EmailVerificationPopup/TokenInputPopup/TokenInputPopup.controller"
  )
);
const ProUnlockedInfoPopupController = dynamic(() =>
  import(
    "@/mvcComponents/Commons/Popups/ProUnlockedInfoPopup/ProUnlockedInfoPopup.controller"
  )
);


import usePopupActions, {
  FORGOT_PASSWORD_POPUP,
  GUEST_LOGIN_POPUP,
  VALUE_CAPTURE_REGISTER_POPUP,
  JOIN_WAITLIST_TYPEFORM,
  LOGIN_POPUP,
  REGISTER_POPUP,
  WAITLIST_POPUP,
  ASSIGNMENT_SUBMIT_POPUP,
  ASSIGNMENT_SUBMISSIONS_POPUP,
  JOB_ALERT_POPUP,
  MCQ_POPUP,
  PAYMENT_OPTION_POPUP,
  IFRAME_POPUP,
  REPORT_JOB_POPUP,
  PHONE_NUMBER_POPUP,
  IGDC_POPUP,
  FEE_STRUCTURE_POPUP,
  EDIT_GAME_BUILD_INFO_POPUP,
  RESUME_POPUP,
  REVIEW_FEEDBACK_POPUP,
  REVIEW_FEEDBACK_ALERT_POPUP,
  WAITLIST_APPLICATION_COMPLETED,
  APPLY_TO_SIMILAR_JOBS_POPUP,
  RESUBMIT_FAILED_ASSIGNMENT_POPUP,
  MESSAGE_POPUP,
  ASSESSMENT_POPUP,
  ADD_SKILLS_POPUP,
  ADS_JOINWAITLIST_POPUP,
  EMAIL_VERIFICATION_EMAIL_INPUT_POPUP,
  EMAIL_VERIFICATION_TOKEN_INPUT_POPUP,
  JOB_APPLY_CONFIRMATION_POPUP,
  INPUT_POPUP,
  INPUT_FORM_POPUP,
  PRO_REGISTER_POPUP,
  PRO_UNLOCKED_INFO_POPUP,
} from "@/store/actions/popupAction";
import { useEffect } from "react";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UserCourseStatusEnum from "@/outscal-commons-frontend/Enums/UserCourseStatusEnum";
import AdsJoinWaitlistPopup from "@/Components/Popup/AdsJoinWaitlistPopup";
import AddSkillPopup from "@/mvcComponents/Commons/Popups/AddSkillPopup/AddSkillPopup.controller";
import { useRouter } from "next/router";
import InputPopupController from "@/mvcComponents/Commons/Popups/InputPopup/InputPopup.controller";
import PhoneNumberPopup from "@/mvcComponents/CommonUI/Popups/PhoneNumberPopup/PhoneNumberPopup.controller";
import MessagePopup from "@/mvcComponents/Commons/Popups/MessagePopup/MessagePopup.controller";
import ProRegisterPopup from "@/mvcComponents/Commons/Popups/AuthPopups/ProRegisterPopup/ProRegisterPopup.controller";
import { bootcampSlugs } from "@/staticData/constant";

const PopupService = () => {
  const { popup, data, showPopup, hidePopups } = usePopupActions();
  const { user } = useUserActions();
  const router = useRouter();
  const { clickFrom } = router.query;

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const joinWaitlisted = user?.userCourseStatus
      ? user.userCourseStatus?.filter(
        (courseStatus) =>
          courseStatus.current_status?.status ===
          UserCourseStatusEnum.getValue("waitlisted")
      )
      : false;

    const inProgressWaitlistApplication = sessionStorage.getItem(
      "inprogress-waitlist-applciation"
    );

    if (router.query.checkLogin) {
      showPopup(LOGIN_POPUP, {
        heading: "Check Job Application",
        subHeading:
          "Log in to your account to check the status of your job application.",
      });
    }

    if (
      joinWaitlisted.length > 0 &&
      !router.pathname?.includes("courses") &&
      !user.userResume &&
      !popup &&
      !router.pathname?.includes("scholarship") &&
      !router.pathname?.includes("auth/discord") &&
      !router.pathname?.includes("auth/google") &&
      !router.pathname.includes("profile/edit") &&
      clickFrom?.includes("course-leads-form") &&
      !inProgressWaitlistApplication && courseSlug && bootcampSlugs.includes(courseSlug)) {
      showPopup(RESUME_POPUP);
    }

    const courseSlug = router.query?.courseSlug


    if (
      joinWaitlisted.length > 0 &&
      !user.userJobProfile?.phone_number &&
      !popup &&
      !router.pathname?.includes("scholarship") &&
      !router.pathname?.includes("auth/discord") &&
      !router.pathname?.includes("auth/google") &&
      !router.pathname.includes("profile/edit") &&
      !clickFrom?.includes("scholarship") &&
      !inProgressWaitlistApplication && courseSlug && bootcampSlugs.includes(courseSlug)
    ) {
      showPopup(PHONE_NUMBER_POPUP, {
        inputHeading:
          "Your phone number speeds up application processing to 2 days.",
      });
    }
  }, [user, router.isReady, router.query.clickFrom]);

  useEffect(() => {
    if (router.isReady) {
      if (
        router.query.popup &&
        (popup == null || router.query.popup != popup.toString())
      ) {
        showPopup(router.query.popup);
      } else if (!router.query.popup) {
        hidePopups();
      }
    }
  }, [router.isReady, router.query.popup]);

  return (
    <>
      {popup == ADS_JOINWAITLIST_POPUP && <AdsJoinWaitlistPopup {...data} />}
      {popup == LOGIN_POPUP && <LoginPopup {...data} />}
      {popup == REGISTER_POPUP && <RegisterPopup {...data} />}
      {popup == PRO_REGISTER_POPUP && <ProRegisterPopup {...data} />}
      {popup == VALUE_CAPTURE_REGISTER_POPUP && (
        <ValueCaptureRegisterPopup {...data} />
      )}
      {popup == FORGOT_PASSWORD_POPUP && <ForgotPasswordPopup {...data} />}
      {popup == GUEST_LOGIN_POPUP && <GuestLoginPopup {...data} />}
      {popup == MESSAGE_POPUP && <MessagePopup {...data} />}
      {popup == WAITLIST_POPUP && <WaitlistConfirmationPopup {...data} />}
      {popup == ASSIGNMENT_SUBMIT_POPUP && (
        <ShowAssignmentSubmitPopUp {...data} />
      )}
      {popup === ASSIGNMENT_SUBMISSIONS_POPUP && (
        <ShowAssignmentSubmissionsPopup {...data} />
      )}
      {popup === JOIN_WAITLIST_TYPEFORM && (
        <JoinWaitlistTypeformPopup {...data} />
      )}
      {popup === JOB_ALERT_POPUP && <JobAlertPopup {...data} />}
      {popup === MCQ_POPUP && <McqPopup {...data} />}
      {popup === PAYMENT_OPTION_POPUP && <PaymentOptionPopup {...data} />}
      {popup === IFRAME_POPUP && <IframePopup {...data} />}
      {popup === REPORT_JOB_POPUP && <ReportJobPopup {...data} />}
      {popup == PHONE_NUMBER_POPUP && <PhoneNumberPopup {...data} />}
      {popup === IGDC_POPUP && <IGDCPopup {...data} />}
      {popup === FEE_STRUCTURE_POPUP && <FeeStructurePopup {...data} />}
      {popup === EDIT_GAME_BUILD_INFO_POPUP && (
        <EditGameBuildInfoPopup {...data} />
      )}
      {popup === RESUME_POPUP && <ResumePopup {...data} />}
      {popup === REVIEW_FEEDBACK_POPUP && <ReviewFeedbackPopup {...data} />}
      {popup === REVIEW_FEEDBACK_ALERT_POPUP && (
        <ReviewFeedbackAlertPopup {...data} />
      )}
      {popup == WAITLIST_APPLICATION_COMPLETED && (
        <WaitlistApplicationCompletedPopup {...data} />
      )}
      {popup === APPLY_TO_SIMILAR_JOBS_POPUP && (
        <ApplyToSimilarJobsPopup {...data} />
      )}
      {popup === RESUBMIT_FAILED_ASSIGNMENT_POPUP && (
        <ResubmitFailedAssignmentPopup {...data} />
      )}
      {popup === ASSESSMENT_POPUP && <AssessmentPopupController {...data} />}
      {popup === ADD_SKILLS_POPUP && <AddSkillPopup {...data} />}
      {popup == EMAIL_VERIFICATION_EMAIL_INPUT_POPUP && (
        <EmailInputPopupController {...data} />
      )}
      {popup == EMAIL_VERIFICATION_TOKEN_INPUT_POPUP && (
        <TokenInputPopupController {...data} />
      )}
      {popup == JOB_APPLY_CONFIRMATION_POPUP && (
        <JobApplyConfirmationPopupController {...data} />
      )}
      {popup == INPUT_POPUP && <InputPopupController {...data} />}
      {popup == INPUT_FORM_POPUP && <InputFormPopupController {...data} />}
      {popup == PRO_UNLOCKED_INFO_POPUP && (
        <ProUnlockedInfoPopupController {...data} />
      )}
    </>
  );
};

export default PopupService;
