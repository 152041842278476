import { createSlice } from "@reduxjs/toolkit";

const userCourseStatusSlice = createSlice({
  name: "userCourseStatus",
  initialState: {
    currentStatus: null,
    coursePermissions: null,
    currentScholarshipStatus: null,
    currentScholarshipType: null,
    courseSlug: null,
    courseType: null,
    previewLink: null,
    courseDiscountTime: null,
    waitListNumber: null,
    brochureLink: null,
  },
  reducers: {
    setCurrentStatus: (state, actions) => {
      state.currentStatus = actions.payload;
      return state;
    },
    setCurrentPermissions: (state, actions) => {
      state.coursePermissions = actions.payload;
      return state;
    },
    setScholarshipStatus: (state, actions) => {
      state.currentScholarshipStatus = actions.payload;
      return state;
    },
    setScholarshipType: (state, actions) => {
      state.currentScholarshipType = actions.payload;
      return state
    },
    setCourseSlug: (state, actions) => {
      state.courseSlug = actions.payload;
      return state;
    },
    setCourseType: (state, actions) => {
      state.courseType = actions.payload;
      return state;
    },
    setPreviewLink: (state, actions) => {
      state.previewLink = actions.payload;
      return state;
    },
    setCourseDiscountTime: (state, actions) => {
      state.courseDiscountTime = actions.payload;
      return state;
    },
    setWaitListNumber: (state, actions) => {
      state.waitListNumber = actions.payload;
      return state;
    },
    setBrochureLink: (state, actions) => {
      state.brochureLink = actions.payload;
      return state;
    },
    clearCourseStatus: (state, actions) => {
      return {
        currentStatus: null,
        coursePermissions: null,
        courseSlug: null,
        courseType: null,
        previewLink: null,
        courseDiscountTime: null,
        waitListNumber: null,
        brochureLink: null,
        currentScholarshipStatus: null,
        currentScholarshipType: null
      };
    },
  },
});

export const {
  setCurrentStatus,
  setCurrentPermissions,
  setCourseSlug,
  setCourseType,
  setPreviewLink,
  setCourseDiscountTime,
  setWaitListNumber,
  setBrochureLink,
  clearCourseStatus,
  setScholarshipStatus,
  setScholarshipType
} = userCourseStatusSlice.actions;

export default userCourseStatusSlice.reducer;
