import { AnalyticsManager } from "@/outscal-commons-frontend/Managers";
import UTMManager from "./UTMManager";
import BasePageManager from "./PageServices/BasePageManager";

type FeatureFlags = {
  forcePhoneNumberOnAdsJW: boolean;
};
class ClientAppManager {
  private isInitialized: boolean = false;
  public static instance: ClientAppManager;
  public featureFlags: FeatureFlags;
  public pageManager: BasePageManager<any> | null = null;

  private constructor() {}

  public static getInstance(): void {
    if (!this.instance) {
      this.instance = new this();
    }
  }

  public initialize(): void {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = this.initializeServices();
  }

  private initializeServices(): boolean {
    if (typeof window !== "undefined") {
      UTMManager.getInstance();
      return true;
    }
    return this.isInitialized;
  }

  public appMounted(): void {
    AnalyticsManager.checkAuth();
  }

  public setFeatureFlags(featureFlags: FeatureFlags): void {
    this.featureFlags = featureFlags;
  }

  public setPageManager(pageManagerClass) {
    this.pageManager = pageManagerClass;
  }

  public reset(): void {
    this.isInitialized = false;
    console.log("App manager reset");
  }
}

export default ClientAppManager;
