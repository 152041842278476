const Constant = {
  fullPageMaxWidth: "1366px",
  NAVBAR_HEIGHT: "68px",
  LMSHeaderHeight: "60px",
  LMSLeftPadding: "40px",
  LMSRightPadding: "40px",
  LMSTopPadding: "20px",
  LMSDownPadding: "0px",
  LMSContentMinHeight: "65vh",
  LMSMaxWidth: "1000px",
  OUTSCAL_DISCORD_SERVER: "https://discord.gg/QTEaVyn",
  OUTSCAL_DISCORD_SERVER_LINK2: "https://discord.gg/X48hsXQ5nV",
  EXAMPLE_GAMES_PROFILE_USERNAME: "mohaksharma",
  PAGE_LIMIT: 9,
  COMPANY_PAGE_LIMIT: 9,
  LOGGED_IN_PAGE_LIMIT: 9,
  WebsiteMaxWidth: "1100px",
};

export const SCHOLARSHIP_COURSE_SLUG = "full-stack-game-development";

export const ContentOnlyCourses = [
  "game-development-advance-content",
  "advanced-game-development-in-unity",
  "unity-game-dev-scholarship-program",
];

export const bootcampSlugs = [
  "full-stack-game-development",
  "game-development-fast-track",
  "game-development-advance",
];

export const defaultProfilePics = [
  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/female_profile_pic.png",
  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/mail_profile_pic.png",
  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/mail_profile_pic2.png",
  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/game_profile_pic3.png",
  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/game_profile_pic1.png",
  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/game_profile_pic2.png",
];

export default Constant;
