import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import usePopupActions, {
  RESUME_POPUP,
  WAITLIST_APPLICATION_COMPLETED,
} from "@/store/actions/popupAction";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
import {
  phoneNumberFormData,
  PhoneNumFormHeading,
  defaultValues,
} from "./PhoneNumberPopup.model";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import PhoneNumberPopupView from "./PhoneNumberPopup.view";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";

const PhoneNumberPopup = ({ formHeading, inputHeading }) => {
  const { getAndSetUserProfile, user } = useUserActions();
  const formUIRef = useRef(null);
  const { hidePopups, showPopup } = usePopupActions();

  const initialForceMobilePopup = useRef(
    typeof window !== "undefined" &&
      localStorage.getItem("forceMobilePopup") === "true"
  ).current;

  useEffect(() => {
    if (initialForceMobilePopup) {
      localStorage.setItem("forceMobilePopup", "false");
    }
  }, [initialForceMobilePopup]);

  const openApplicationCompletedPopup = () => {
    showPopup(WAITLIST_APPLICATION_COMPLETED);
  };

  const onSubmit = async (formValues) => {
    formValues.pushNumberInAT = true;
    let response = await NetworkManager.put("/edit-profile", {
      profile: JSON.stringify(formValues),
    });
    if (response.statusCode === 200) {
      getAndSetUserProfile();

      if (!user.userResume) {
        const onResumeSubmit = () => {
          openApplicationCompletedPopup();
        };
        showPopup(RESUME_POPUP, { onResumeSubmit });
      } else {
        openApplicationCompletedPopup();
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  phoneNumberFormData.phone_number = {
    ...phoneNumberFormData.phone_number,
    heading: inputHeading || phoneNumberFormData.phone_number.heading,
  };

  return (
    <PhoneNumberPopupView
      hidePopups={hidePopups}
      onSubmit={onSubmit}
      formData={phoneNumberFormData}
      formUIRef={formUIRef}
      defaultValues={defaultValues}
      formHeading={formHeading || PhoneNumFormHeading}
      analyticsIntentText={"phone-number-popup"}
      dismissable={
        !initialForceMobilePopup &&
        !UTMManager.instance.getLandingIntent()?.url?.includes("paid")
      }
    />
  );
};

export default PhoneNumberPopup;
