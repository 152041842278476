import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloLink, fromPromise } from "@apollo/client";

class ApolloClientManager {
  constructor() {
    this.client = this.createApolloClient();
    this.inFlightRequests = new Map();
  }

  createShortKey(operation) {
    return JSON.stringify({
      operationName: operation.operationName,
      variables: operation.variables,
    });
  }

  createApolloClient = () => {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL,
    });

    const authLink = setContext((_, { headers }) => {
      let token = typeof localStorage !== "undefined" ? JSON.parse(localStorage.getItem("userData") || "{}")?.token : "";
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const dedupeLink = new ApolloLink((operation, forward) => {
      const key = this.createShortKey(operation);

      if (this.inFlightRequests.has(key)) {
        return fromPromise(this.inFlightRequests.get(key));
      }

      const requestPromise = new Promise((resolve, reject) => {
        forward(operation).subscribe({
          next: resolve,
          error: reject,
        });
      })
        .then((result) => {
          this.inFlightRequests.delete(key);
          return result;
        })
        .catch((error) => {
          this.inFlightRequests.delete(key);
          throw error;
        });

      this.inFlightRequests.set(key, requestPromise);

      return fromPromise(requestPromise);
    });

    return new ApolloClient({
      link: ApolloLink.from([authLink, dedupeLink, httpLink]),
      cache: new InMemoryCache(),
    });
  };
}

export default new ApolloClientManager();