import React from "react";
import dynamic from "next/dynamic";
const FormBuilder = dynamic(
  () => import("@/outscal-commons-frontend/FormBuilder/FormUI"),
  {
    ssr: false, // Optional: Disable server-side rendering if needed
  }
);
import PopupTemplate from "@/outscal-commons-frontend/Styled/PopupTemplate";
import {
  CloseIcon,
  PhoneNumPopupHeading,
  Root,
  SubmitButton,
} from "./PhoneNumberPopup.styles";
import { RowDiv } from "@/outscal-commons-frontend/Styled";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const PhoneNumberPopupView = ({
  hidePopups,
  onSubmit,
  formData,
  formUIRef,
  defaultValues,
  formHeading,
  dismissable,
  analyticsIntentText,
}) => {
  return (
    <PopupTemplate
      setShowPopUp={() => {
        dismissable ? hidePopups() : () => {};
      }}
      padding="20px"
      maxWidth="400px"
      width="90%"
      bgColor="white"
      height="fit-content"
      id={analyticsIntentText}
    >
      <Root>
        {dismissable && (
          <RowDiv style={{ position: "relative" }}>
            <CloseIcon
              onClick={hidePopups}
              data-analytics={[
                UIElements.BUTTON,
                `register_close${analyticsIntentText}`,
              ]}
            />
          </RowDiv>
        )}
        <PhoneNumPopupHeading>{formHeading}</PhoneNumPopupHeading>
        <FormBuilder
          Inputs={formData}
          inputStyles={{ direction: "column" }}
          formStyles={{ gap: "16px" }}
          onSubmit={onSubmit}
          submitText="Submit"
          SubmitButton={SubmitButton}
          ref={formUIRef}
          defaultValues={defaultValues}
        />
      </Root>
    </PopupTemplate>
  );
};

export default PhoneNumberPopupView;
