/**
 * A class that represents a base enumeration with an associated labels list.
 * @class
 */
class BaseEnum {
  /**
   * Constructs a new BaseEnum instance.
   * @param {Array} enums - An array of enumeration values.
   * @param {Array} labelsList - An array of labels corresponding to the enumeration values.
   */

  constructor(enums, labelsList) {
    this.Enum = enums;
    this.LabelsList = labelsList.map((item, index) => {
      return {
        id: index,
        label: item,
      };
    });
  }

  /**
   * Retrieves the enumeration value at the specified index.
   * @param {number} number - The index of the enumeration value to retrieve.
   * @returns {*} The enumeration value at the specified index or undefined if the index is out of bounds.
   */
  getEnum = (number) => {
    if (number < 0 || number >= this.Enum.length) {
      console.log("Length Out OfBound for Enum",this.Enum);
      return;
    }
    return this.Enum[number];
  };

  /**
   * Retrieves the index of the specified enumeration value.
   * @param {string} str - The enumeration value to find the index of.
   * @returns {number} The index of the specified enumeration value or -1 if the value is not found.
   */
  getValue = (str) => {
    return this.Enum.indexOf(str);
  };

  /**
   * Retrieves the list of enumeration values.
   * @returns {Array} The list of enumeration values.
   */
  getList = () => {
    return this.Enum;
  };

  /**
   * Retrieves the list of labels for display purposes.
   * @returns {Array} The list of labels corresponding to the enumeration values.
   */
  getDisplayList = () => {
    return this.LabelsList;
  };

  getDisplayValueFromIndex = (idx) => {
    return this.LabelsList[idx]?.label;
  };

  getFilterList = (idsArray) => {
    return idsArray?.map((id) => ({
      id: id,
      label: this.getDisplayValueFromIndex(id),
    }))
  }
}

/**
 * Exports the BaseEnum class as a default export.
 */
export default BaseEnum;
