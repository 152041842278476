class LazySingleton {
  constructor() {
    if (this.constructor.instance) {
      return this.constructor.instance;
    }
    this.constructor.instance = this;
  }

  static getInstance(...props) {
    if (!this.instance) {
      this.instance = new this(...props);
    }
    return this.instance;
  }

  destroy() {
    this.instance = null;
    this.constructor.instance = null;
  }
}

export default LazySingleton;
